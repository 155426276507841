import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import CONFIG from "./config";
import "./App.css";

//Truncates string for address display?
const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  //For redux
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const [claimingNft, setClaimingNft] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [english, setLanguage] = useState(false);
  const [feedback, setFeedback] = useState(0);

  //Most important minting function. Deal with both whitelist and public sale.
  const claimNFTs = () => {
    // Ternery for pubsale vs WL sale cost.
    let cost = data.isPubSale ? CONFIG.WEI_COST_PUB : CONFIG.WEI_COST_WL;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(1);
    setClaimingNft(true);

    //Grabbing WL vs Pub Mint
    let mintfunction = blockchain.smartContract.methods.pubMint;
    if (data.isWLSale) {
      mintfunction = blockchain.smartContract.methods.wlMint;
    }

    //Execiute the miting function.
    mintfunction(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback(3);
        setClaimingNft(false);
        setMintAmount(1);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(2);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
        setMintAmount(1);
      });
  };

  const printFeedback = () => {
    let feedbackStatement = "";
    if (feedback == 0) {
      feedbackStatement = english
        ? `Click "BUY" to mint your NFT.`
        : `"BUY" をクリックして購入してください。`;
    } else if (feedback == 1) {
      feedbackStatement = english
        ? `Minting your ${CONFIG.NFT_NAME}...`
        : `${CONFIG.NFT_NAME} をミント中...`;
    } else if (feedback == 2) {
      feedbackStatement = english
        ? `Purchase succsesfully completed.`
        : `購入完了です！ありがとうございました。`;
    } else {
      feedbackStatement = english
        ? "Sorry, something went wrong please try again later."
        : "エラーが発生しました。時間をおいてトライしてください。";
    }
    return <div style={linestyle}>{feedbackStatement}</div>;
  };

  const mintingInfo = () => {
    let mintingStatus = "";
    blockchain.errorMsg !== "" ? (
      <div style={linestyle}>{blockchain.errorMsg}</div>
    ) : null;
    if (!isMinting()) {
      mintingStatus = english ? "Currently not minting." : "現在準備中です。";
    } else if (data.isWLSale) {
      mintingStatus = english
        ? "We are WL minting."
        : "ホワイトリストセール中です。";
    } else if (data.isPubSale) {
      mintingStatus = english
        ? "We are public minting."
        : "パブリックセール中です。";
    }
    return <div>{mintingStatus}</div>;
  };

  const printInfo = () => {
    let whiteListStatus = "";
    if (data.isWhiteListed > 0) {
      whiteListStatus = english
        ? "You are whitelisted and you can mint " +
          data.isWhiteListed.toString() +
          "."
        : "ホワイトリストのミントチケットがあと" +
          data.isWhiteListed.toString() +
          "個あります。";
    } else {
      whiteListStatus = english
        ? "You are not whitelisted."
        : "ホワイトリストのミントチケットはありません。";
    }

    let pubListStatus = english
      ? "You have minted " +
        data.numPubMinted.toString() +
        " out of " +
        data.max_pub_mint.toString() +
        "."
      : "アドレス上限の" +
        data.max_pub_mint.toString() +
        "個のうち" +
        data.numPubMinted.toString() +
        "個ミントしています。";

    return (
      <>
        <div>
          {english
            ? "Your wallet address: " + blockchain.account
            : blockchain.account + "で接続しています。"}
        </div>
        {isWLMinting() ? <div>{whiteListStatus}</div> : null}
        {isPUBMinting() ? <div>{pubListStatus}</div> : null}
      </>
    );
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    let allowedMintAmount = 1;
    if (isWLMinting()) {
      allowedMintAmount = Math.max(data.isWhiteListed, 1);
    } else if (isPUBMinting()) {
      allowedMintAmount = Math.max(data.max_pub_mint - data.numPubMinted, 1);
    }
    if (newMintAmount > allowedMintAmount) {
      newMintAmount = allowedMintAmount;
    }
    setMintAmount(newMintAmount);
  };

  const changeLanguage = () => {
    setLanguage(!english);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const isBlockChainConnected = () => {
    return blockchain.account === "" || blockchain.smartContract === null;
  };

  const isMinting = () => {
    return (data.isWLSale || data.isPubSale) && !data.isPaused;
  };

  const isWLMinting = () => {
    return data.isWLSale && !data.isPaused;
  };

  const isPUBMinting = () => {
    return data.isPubSale && !data.isPaused;
  };

  const isWhitelisted = () => {
    return data.isWhiteListed > 0;
  };

  const canPublicMint = () => {
    return data.numPubMinted < data.max_pub_mint;
  };

  const buttonDisable = () => {
    return (
      claimingNft ||
      !isMinting() ||
      data.loading ||
      (isWLMinting() && !isWhitelisted()) ||
      (isPUBMinting() && !canPublicMint())
    );
  };

  const languageButton = () => {
    let buttonName = "";
    if (english) {
      buttonName = "日本語";
    } else {
      buttonName = "English";
    }
    return (
      <button
        class="button-80"
        style={{
          height: "2.5rem",
          position: "absolute",
          top: "1rem",
          left: "1.5rem",
        }}
        onClick={(e) => {
          e.preventDefault();
          changeLanguage();
        }}
      >
        {buttonName}
      </button>
    );
  };

  const mintButton = () => {
    let buttonName = "";
    if (claimingNft) {
      buttonName = "BUSY";
    } else if (buttonDisable()) {
      buttonName = "PAUSED";
    } else {
      buttonName = "BUY";
    }

    return (
      <div
        style={{
          display: "flex",
          "flex-direction": "row",
          "justify-content": "center",
          "align-items": "center",
        }}
      >
        <button
          class="button-80"
          style={{ height: "2rem" }}
          disabled={buttonDisable() ? 1 : 0}
          onClick={(e) => {
            e.preventDefault();
            decrementMintAmount();
          }}
        >
          -
        </button>
        <div
          style={{
            display: "flex",
            "jutify-content": "center",
            "align-items": "center",
            "margin-right": "0.7rem",
            "margin-left": "0.7rem",
            "font-size": "20px",
          }}
        >
          {mintAmount}
        </div>
        <button
          class="button-80"
          style={{ height: "2rem" }}
          disabled={buttonDisable() ? 1 : 0}
          onClick={(e) => {
            e.preventDefault();
            incrementMintAmount();
          }}
        >
          +
        </button>
        <button
          class="button-80"
          style={{ "margin-left": "30px", height: "3rem" }}
          disabled={buttonDisable() ? 1 : 0}
          onClick={(e) => {
            e.preventDefault();
            claimNFTs();
            getData();
          }}
        >
          {buttonName}
        </button>
      </div>
    );
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  let logostyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    "margin-top": "40px",
    "margin-bottom": "30px",
    position: "relative",
  };

  let logostyle1 = {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
    "margin-top": "20px",
  };

  let linestyle = {
    "text-align": "center",
    "line-height": "20px",
    "margin-top": "10px",
    "margin-bottom": "10px",
  };

  return (
    <div style={{ backgroundImage: 'url("/bg.png")' }}>
      <div style={logostyle}>
        <img
          src="/sjglogo.png"
          alt="logo"
          style={{ maxWidth: "31rem", width: "80%" }}
        />
        {languageButton()}
        <a
          href="https://twitter.com/Y_SakuraNFT"
          style={{ position: "absolute", top: "1rem", right: "1.5rem" }}
        >
          <img
            src="/twitter-logo.png"
            alt="Twitter"
            style={{ width: "2.5rem" }}
          />
        </a>
        {true ? (
          <a
            href={CONFIG.MARKETPLACE_LINK}
            style={{ position: "absolute", top: "1rem", right: "5rem" }}
          >
            <img
              src="/opensea-logo.png"
              alt="Opensea"
              style={{ width: "2.5rem" }}
            />
          </a>
        ) : null}
      </div>
      <div style={linestyle}>
        <div>
          <div>
            1 Sakura JK (whitelist sale): {CONFIG.DISPLAY_COST_WL}{" "}
            {CONFIG.NETWORK.SYMBOL}.
          </div>
          <div>
            1 Sakura JK (public sale): {CONFIG.DISPLAY_COST_PUB}{" "}
            {CONFIG.NETWORK.SYMBOL}.
          </div>{" "}
        </div>
      </div>
      <>
        {isBlockChainConnected() ? (
          <>
            <div
              style={{
                display: "flex",
                "flex-direction": "row",
                "justify-content": "center",
                "margin-top": "20px",
              }}
            >
              <button
                class="button-80"
                style={{ height: "3rem" }}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(connect());
                  getData();
                }}
              >
                CONNECT
              </button>
            </div>
            {blockchain.errorMsg !== "" ? (
              <div style={linestyle}>{blockchain.errorMsg}</div>
            ) : null}
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                "flex-direction": "row",
                "justify-content": "center",
                "margin-top": "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  "jutify-content": "center",
                  "align-items": "center",
                  "margin-right": "4rem",
                  "font-size": "30px",
                }}
              >
                {data.totalSupply} / {CONFIG.MAX_SUPPLY}
              </div>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                english ? (
                  <div
                    style={{
                      display: "flex",
                      "jutify-content": "center",
                      "align-items": "center",
                    }}
                  >
                    The sale has ended.
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        "jutify-content": "center",
                        "align-items": "center",
                      }}
                    >
                      完売ですっ！！
                    </div>
                  </>
                )
              ) : (
                mintButton()
              )}
            </div>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY
              ? null
              : printFeedback()}
          </>
        )}
      </>
      <div style={linestyle}>
        {isBlockChainConnected() ? null : printInfo()}
      </div>
      <div style={logostyle1}>
        <img
          src="/kokubann1.jpg"
          alt="logo"
          style={{ maxWidth: "31rem", width: "80%" }}
        />
      </div>
      <div style={logostyle1}>
        <img
          src="/kokubann2.jpg"
          alt="logo"
          style={{ maxWidth: "31rem", width: "80%" }}
        />
      </div>
      <div style={linestyle}>
        {english ? (
          <>
            <div style={{ "margin-top": "20px" }}>
              Please make sure that you are connected to the{" "}
              {CONFIG.NETWORK.NAME} Mainnet.
            </div>
            <div>
              Note: Once you make the purchase, you cannot revert the action.
            </div>
            <div style={{ "margin-top": "10px", "padding-bottom": "1.5rem" }}>
              The gas limit is set to {CONFIG.GAS_LIMIT} for the contract to
              successfully mint your NFT.
            </div>
          </>
        ) : (
          <>
            <div style={{ "margin-top": "20px" }}>
              {CONFIG.NETWORK.NAME} メインネットに接続してください。
            </div>
            <div>購入後にNFTの返却はできません。</div>
            <div style={{ "margin-top": "10px", "padding-bottom": "1.5rem" }}>
              購入トランザクションのガスリミットは {CONFIG.GAS_LIMIT}{" "}
              に設定されています。
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
