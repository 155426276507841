// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      // Grab # of NFTs currently minted
      let totalSupply = parseInt(
        await store
          .getState()
          .blockchain.smartContract.methods.totalMinted()
          .call()
      );

      // Grab Boolean wheather it is paused.
      let isPaused = await store
        .getState()
        .blockchain.smartContract.methods.isPaused()
        .call();

      // Grab Boolean wheather it is in whitliest sale mode.
      let isWLSale = await store
        .getState()
        .blockchain.smartContract.methods.isWLSale()
        .call();

      // Grab Boolean wheather it is in Public sale mode.
      let isPubSale = await store
        .getState()
        .blockchain.smartContract.methods.isPubSale()
        .call();

      // Grab max pub int and make it into an integer.
      let max_pub_mint = parseInt(
        await store
          .getState()
          .blockchain.smartContract.methods.MAX_PUB_MINT()
          .call()
      );

      // See if you are white listed and returns WL numbers.
      let address = store.getState().blockchain.account;
      let isWhiteListed = parseInt(
        await store
          .getState()
          .blockchain.smartContract.methods.whitelist(address)
          .call()
      );

      // Grab how many you have minted so far and puts it into a number.
      let numPubMinted = parseInt(
        await store
          .getState()
          .blockchain.smartContract.methods.totalPubMint(address)
          .call()
      );

      //console.log([isPaused, isWLSale, isPubSale]);
      dispatch(
        fetchDataSuccess({
          totalSupply,
          isPaused,
          isWLSale,
          isPubSale,
          isWhiteListed,
          numPubMinted,
          max_pub_mint,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
