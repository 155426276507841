const initialState = {
  loading: false,
  totalSupply: 0,
  isPaused: true,
  isWLSale: false,
  isPubSale: false,
  isWhiteListed: 0,
  numPubMinted: 0,
  max_pub_mint: 10,
  cost: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        totalSupply: action.payload.totalSupply,
        isPaused: action.payload.isPaused,
        isWLSale: action.payload.isWLSale,
        isPubSale: action.payload.isPubSale,
        isWhiteListed: action.payload.isWhiteListed,
        numPubMinted: action.payload.numPubMinted,
        max_pub_mint: action.payload.max_pub_mint,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
