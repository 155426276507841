const contract_address = "0x1Ac6712CEC4DBe9780671c052f80407BE25050D9";

const CONFIG = {
  CONTRACT_ADDRESS: contract_address,
  SCAN_LINK:
    "https://etherscan.io/address/0x1Ac6712CEC4DBe9780671c052f80407BE25050D9",
  NETWORK: {
    NAME: "Ethereum",
    SYMBOL: "ETH",
    ID: 1,
  },
  NFT_NAME: "Sakura JK Gacha",
  SYMBOL: "SJG",
  MAX_SUPPLY: 366,
  WEI_COST_PUB: 30000000000000000,
  WEI_COST_WL: 20000000000000000,
  DISPLAY_COST_PUB: 0.03,
  DISPLAY_COST_WL: 0.02,
  GAS_LIMIT: 285000,
  MARKETPLACE: "Opensea",
  MARKETPLACE_LINK: "https://opensea.io/collection/sakura-jk-gacha",
  SHOW_BACKGROUND: true,
};

export default CONFIG;
